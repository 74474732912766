import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from '../services/auth.service';

@Directive({
  selector: '[appAuthorize]',
})
export class AuthorizedDirective {
  constructor(
    private readonly authService: AuthService,
    private readonly viewRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>,
  ) {}

  @Input()
  set appAuthorize(roles: string[]) {
    if (!roles?.length) {
      this.viewRef.createEmbeddedView(this.templateRef);
      return;
    }

    const hasRoles = this.authService.hasRoles(...roles);
    if (hasRoles) {
      this.viewRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewRef.clear();
    }
  }
}
