export enum BranchName {
  Schools = 'Schools',
  FirstResponders = 'FirstResponders',
  HealthcareWorkers = 'HealthcareWorkers'
}

export enum BranchFormattedName {
  Schools = 'Schools',
  FirstResponders = 'First Responders',
  HealthcareWorkers = 'Healthcare Workers'
}
