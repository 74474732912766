import {Inject, Injectable} from '@angular/core';
import {StorageType} from '../enums/storage-type.enum';
import {KeyPrefix, StorageBase} from '../abstracts/storage-base';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService extends StorageBase{

  constructor(@Inject(KeyPrefix) keyPrefix: string) {
    super(StorageType.Session, keyPrefix);
  }
}
