import {Option} from './interfaces/option';
import {BranchFormattedName} from './enums/branch';
import {
  BranchName,
  CaseStatus,
  DepartmentType, IncidentAccessLevel,
  IncidentReportStatus,
  IncidentSeverityLevel,
  SupportPhase,
  VisitWaitTime
} from './apis/api.client';

export const SEX_OPTIONS = [
  {value: 'male', label: 'Male'},
  {value: 'female', label: 'Female'},
  {value: 'intersex', label: 'Intersex'},
];

export const getSexOptionLabel = (value: string): string => {
  const option = SEX_OPTIONS.find((option) => option.value === value);
  // assume other sex was inputted if option not found
  return option === undefined ? value : option.label;
};

export const GENDER_OPTIONS = [
  {value: 'male', label: 'Male'},
  {value: 'female', label: 'Female'},
  {value: 'nonBinary', label: 'Non-Binary'},
  {value: 'transgender', label: 'Transgender'},
  {value: 'genderFluid', label: 'Gender Fluid'},
];

export const getGenderOptionLabel = (value: string): string => {
  const option = GENDER_OPTIONS.find((option) => option.value === value);
  // assume other gender was inputted if option not found
  return option === undefined ? value : option.label;
};

export const PRONOUN_OPTIONS = [
  {value: 'he', label: 'He (him, his, his, and himself)'},
  {value: 'she', label: 'She (her, her, hers, and herself)'},
  {value: 'they', label: 'They (them, their, theirs, and themself)'},
  {value: 'zeZir', label: 'Ze/zir (hir, hir, hirs, and hirself)'},
  {value: 'xe', label: 'Xe (xem, xyr, xyrs, and xemself)'},
  {value: 've', label: 'Ve (ver, vis, vis, and verself)'},
];

export const getPronounOptionLabel = (value: string): string => {
  const option = PRONOUN_OPTIONS.find((option) => option.value === value);
  return option === undefined ? value || 'Not Specified' : option.label;
};

export const SCHOOL_RACE_OPTIONS = [
  {value: 'American Indian or Alaska Native', label: 'American Indian or Alaska Native'},
  {value: 'Black or African American', label: 'Black or African American'},
  {value: 'Asian or Asian American', label: 'Asian or Asian American'},
  {value: 'Hispanic or Latinx/e', label: 'Hispanic or Latinx/e'},
  {value: 'Middle Eastern or North African', label: 'Middle Eastern or North African'},
  {value: 'Native Hawaiian or other Pacific Islander', label: 'Native Hawaiian or other Pacific Islander'},
  {value: 'White/Caucasian', label: 'White/Caucasian'},
  {value: 'Bi-Racial/Multi-Racial', label: 'Bi-Racial/Multi-Racial'},
  {value: 'Prefer not to disclose', label: 'Prefer not to disclose'}
];

export const getRaceOptionLabel = (value: string[]): string[] => {
  return value || ['Unknown'];
};

export const BRANCH_OPTIONS = [
  {value: '62b4c7f143fd34a6f90d7c6b', label: BranchFormattedName.Schools},
  {
    value: '62b4c7f143fd34a6f90d7c6c',
    label: BranchFormattedName.FirstResponders,
  },
  {
    value: '636a4b9e985a36277e961297',
    label: BranchFormattedName.HealthcareWorkers,
  },
];

export const BRANCHES = [
  {value: '62b4c7f143fd34a6f90d7c6b', label: BranchName.Schools},
  {
    value: '62b4c7f143fd34a6f90d7c6c',
    label: BranchName.FirstResponders,
  },
  {
    value: '636a4b9e985a36277e961297',
    label: BranchName.HealthcareWorkers,
  },
];

export const BRANCH_NAME_MAP = [
  {value: BranchName.Schools, label: BranchFormattedName.Schools},
  {
    value: BranchName.FirstResponders,
    label: BranchFormattedName.FirstResponders,
  },
  {
    value: BranchName.HealthcareWorkers,
    label: BranchFormattedName.HealthcareWorkers,
  },
];

export const getBranchOptionLabel = (value: BranchName): BranchFormattedName => {
  const option = BRANCH_NAME_MAP.find((option) => option.value === value);
  return option.label;
};

export const getBranchIdFromName = (branchName: BranchName): string => {
  const id = BRANCHES.find((b) => b.label === branchName);
  return id.value;
};

export const getBranchNameFromId = (branchId: string): BranchName => {
  const id = BRANCHES.find((b) => b.value === branchId);
  return id.label;
};

export const STATE_OPTIONS = [
  {value: 'Alabama', label: 'Alabama'},
  {value: 'Alaska', label: 'Alaska'},
  {value: 'Arizona', label: 'Arizona'},
  {value: 'Arkansas', label: 'Arkansas'},
  {value: 'California', label: 'California'},
  {value: 'Colorado', label: 'Colorado'},
  {value: 'Connecticut', label: 'Connecticut'},
  {value: 'Delaware', label: 'Delaware'},
  {value: 'District of Columbia', label: 'District of Columbia'},
  {value: 'Florida', label: 'Florida'},
  {value: 'Georgia', label: 'Georgia'},
  {value: 'Hawaii', label: 'Hawaii'},
  {value: 'Idaho', label: 'Idaho'},
  {value: 'Illinois', label: 'Illinois'},
  {value: 'Indiana', label: 'Indiana'},
  {value: 'Iowa', label: 'Iowa'},
  {value: 'Kansas', label: 'Kansas'},
  {value: 'Kentucky', label: 'Kentucky'},
  {value: 'Louisiana', label: 'Louisiana'},
  {value: 'Maine', label: 'Maine'},
  {value: 'Maryland', label: 'Maryland'},
  {value: 'Massachusetts', label: 'Massachusetts'},
  {value: 'Michigan', label: 'Michigan'},
  {value: 'Mississippi', label: 'Mississippi'},
  {value: 'Missouri', label: 'Missouri'},
  {value: 'Montana', label: 'Montana'},
  {value: 'Nebraska', label: 'Nebraska'},
  {value: 'Nevada', label: 'Nevada'},
  {value: 'New Hampshire', label: 'New Hampshire'},
  {value: 'New Jersey', label: 'New Jersey'},
  {value: 'New Mexico', label: 'New Mexico'},
  {value: 'New York', label: 'New York'},
  {value: 'North Carolina', label: 'North Carolina'},
  {value: 'North Dakota', label: 'North Dakota'},
  {value: 'Ohio', label: 'Ohio'},
  {value: 'Oklahoma', label: 'Oklahoma'},
  {value: 'Oregon', label: 'Oregon'},
  {value: 'Pennsylvania', label: 'Pennsylvania'},
  {value: 'Rhoda Island', label: 'Rhode Island'},
  {value: 'South Carolina', label: 'South Carolina'},
  {value: 'South Dakota', label: 'South Dakota'},
  {value: 'Tennessee', label: 'Tennessee'},
  {value: 'Texas', label: 'Texas'},
  {value: 'Utah', label: 'Utah'},
  {value: 'Vermont', label: 'Vermont'},
  {value: 'Virginia', label: 'Virginia'},
  {value: 'Washington', label: 'Washington'},
  {value: 'West Virginia', label: 'West Virginia'},
  {value: 'Wisconsin', label: 'Wisconsin'},
  {value: 'Wyoming', label: 'Wyoming'},
];

export const DEPARTMENT_TYPE_OPTIONS: Option<DepartmentType>[] = [
  {value: DepartmentType.School, label: 'School'},
  {value: DepartmentType.Ems, label: 'Ems'},
  {value: DepartmentType.Fire, label: 'Fire'},
  {value: DepartmentType.LawEnforcement, label: 'Law Enforcement'},
  {value: DepartmentType.Dispatch, label: 'Dispatch'},
];

export const NON_SCHOOL_DEPARTMENT_TYPE_OPTIONS: Option<DepartmentType>[] = [
  {value: DepartmentType.Ems, label: 'Ems'},
  {value: DepartmentType.Fire, label: 'Fire'},
  {value: DepartmentType.LawEnforcement, label: 'Law Enforcement'},
  {value: DepartmentType.Dispatch, label: 'Dispatch'},
];

export const RACE_OPTIONS = [
  {value: 'americanIndianOrAlaskaNative', label: 'American Indian or Alaska Native'},
  {value: 'asian', label: 'Asian'},
  {value: 'blackOrAfricanAmerican', label: 'Black or African American'},
  {value: 'nativeHawaiianOrOtherPacificIslander', label: 'Native Hawaiian or Other Pacific Islander'},
  {value: 'white', label: 'White'},
  {value: 'other', label: 'Other:', input: true, useMaterialInput: true},
];

export const ETHNICITY_OPTIONS = [
  {value: 'hispanicOrLatinoOrSpanish', label: 'Hispanic or Latino or Spanish Origin'},
  {value: 'notHispanicOrLatinoOrSpanish', label: 'Not Hispanic or Latino or Spanish Origin'},
];

export const BIOLOGICAL_SEX_OPTIONS = [
  {value: 'male', label: 'Male'},
  {value: 'female', label: 'Female'},
  {value: 'noAnswer', label: 'Prefer Not to Answer'},
];

export const GENDER_IDENTITY_OPTIONS = [
  {value: 'genderNonConforming', label: 'Gender non-conforming'},
  {value: 'intersex', label: 'Intersex'},
  {value: 'transgenderWoman', label: 'Transgender Woman'},
  {value: 'transgenderMan', label: 'Transgender Man'},
  {value: 'cisgenderWoman', label: 'Cisgender (non-trans) woman'},
  {value: 'cisgenderMan', label: 'Cisgender (non-trans) man'},
  {value: 'nonBinary', label: 'Non-binary'},
  {value: 'genderqueer', label: 'Genderqueer'},
  {value: 'twoSpirit', label: 'Two Spirit'},
  {value: 'noAnswer', label: 'Prefer not to answer'},
  {value: 'other', label: 'Tell Us:', input: true, useMaterialInput: true},
];

export const RELIGIOUS_PREFERENCE_OPTIONS = [
  {value: 'christian', label: 'Christian'},
  {value: 'buddhist', label: 'Buddhist'},
  {value: 'hindu', label: 'Hindu'},
  {value: 'muslim', label: 'Muslim'},
  {value: 'jewish', label: 'Jewish'},
  {value: 'sikh', label: 'Sikh'},
  {value: 'agnostic', label: 'Agnostic'},
  {value: 'atheist', label: 'Atheist'},
  {value: 'noReligion', label: 'No religion'},
  {value: 'noAnswer', label: 'Prefer not to answer'},
  {other: true, value: 'other', label: 'Any other religion, please specify:'},
];

export const CASE_STATUS_OPTIONS = [
  {value: CaseStatus.New, label: 'New'},
  {value: CaseStatus.Submitted, label: 'Submitted'},
  {value: CaseStatus.NoAnswer, label: 'No Answer'},
  {value: CaseStatus.Accepted, label: 'Accepted'},
  {value: CaseStatus.InProgress, label: 'In Progress'},
  {value: CaseStatus.Closed, label: 'Closed'},
];

export const VISIT_WAIT_TIME_OPTIONS = [
  {value: VisitWaitTime.Immediately, label: 'Immediately'},
  {value: VisitWaitTime.OneWeek, label: '1 Week'},
  {value: VisitWaitTime.TwoToFourWeeks, label: '2-4 Weeks'},
  {value: VisitWaitTime.OneMonth, label: '1 Month'},
  {value: VisitWaitTime.TwoToThreeMonths, label: '2-3 Months'},
  {value: VisitWaitTime.FourToSixMonths, label: '4-6 Months'},
  {value: VisitWaitTime.SixPlusMonths, label: '6+ Months'},
  {value: VisitWaitTime.NotSpecified, label: 'Not Specified'},
];
export const INCIDENT_SEVERITY_OPTIONS = [
  {value: IncidentSeverityLevel.Low, label: 'Low'},
  {value: IncidentSeverityLevel.Moderate, label: 'Moderate'},
  {value: IncidentSeverityLevel.Severe, label: 'Severe'},
];

export const INCIDENT_ACCESS_OPTIONS = [
  {value: IncidentAccessLevel.AllUsers, label: 'All Users'},
  {value: IncidentAccessLevel.AdminOnly, label: 'Admins Only'},
];

export const INCIDENT_STATUS_OPTIONS = [
  {value: IncidentReportStatus.New, label: 'New'},
  {value: IncidentReportStatus.Updated, label: 'Updated'},
];
export const getVisitWaitTimeLabel = (value: string): string => {
  return VISIT_WAIT_TIME_OPTIONS.find((option) => option.value === value).label;
};

export const DISTANCE_OPTIONS = [
  {label: '5 miles', value: 5},
  {label: '10 miles', value: 10},
  {label: '15 miles', value: 15},
  {label: '25 miles', value: 25},
  {label: '50 miles', value: 50},
  {label: '75 miles', value: 75},
  {label: '100 miles', value: 100}
];

export const SUPPORT_PHASE_OPTIONS = [
  {label: 'Immediate Crisis', value: SupportPhase.ImmediateCrisis},
  {label: '1 Month', value: SupportPhase.OneMonth},
  {label: '3 Months', value: SupportPhase.ThreeMonths},
  {label: '6 Months', value: SupportPhase.SixMonths},
  {label: '12 Months', value: SupportPhase.TwelveMonths},
];
