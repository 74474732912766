import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {MatSelect} from '@angular/material/select';
import {AuthService} from '../../../../auth/services/auth.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import swal from 'sweetalert2';
import {MatOption} from '@angular/material/core';
import {
  AssessmentResultCaseViewModel,
  CaseClient,
  CaseStatus,
  CounselorDeptInformationViewModel,
  CreateCaseViewModel,
  GetAssessmentsForCaseResponse,
  ParticipantDataViewModel,
  SaveFrHcwCaseRequest
} from "../../../apis/api.client";
import {BRANCH_OPTIONS} from "../../../options";

export interface ReachOutForAssistanceDialogData {
  assessmentId: string;
}

export interface ReachOutForAssistanceDialogOutput {
  numberOfAssessmentsSubmitted: number;
}

@Component({
  selector: 'app-reach-out-for-assistance',
  templateUrl: './reach-out-for-assistance.component.html',
  styleUrls: ['./reach-out-for-assistance.component.scss']
})
export class ReachOutForAssistanceComponent implements OnInit {
  form: UntypedFormGroup = this.formBuilder.group({
    counselors: ['', Validators.required],
    assessments: ['', Validators.required],
    comments: ['', Validators.required]
  });

  participant: ParticipantDataViewModel;
  disabledAssessmentResults = new BehaviorSubject<string[]>([]);
  caseResponse = new BehaviorSubject<GetAssessmentsForCaseResponse>(null);
  isLoading = false;
  cancelText = 'Cancel';
  defaultHeight = 625;
  defaultWidth = 800;
  defaultNoResultsHeight = 200;
  defaultNoResultsWidth = 350;

  @ViewChild('counselorSelect') counselorSelect: MatSelect;

  constructor(
    private caseClient: CaseClient,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    public dialogRef: MatDialogRef<ReachOutForAssistanceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReachOutForAssistanceDialogData
  ) {
    dialogRef.disableClose = true;
  }

  get assessmentsResultCases(): AssessmentResultCaseViewModel[] {
    return this.form.get('assessments').value;
  }

  ngOnInit(): void {
    this.isLoading = true;
    const participantId = this.authService.authUser?.userId;

    this.caseClient.getFirstRespondersAssessmentsCase(participantId)
      .subscribe((next) => {
        if (next?.counselors?.length === 0) {
          this.cancelText = 'Close';
          const updatedHeightPixels = this.defaultNoResultsHeight + 'px';
          const updatedWidthPixels = this.defaultNoResultsWidth + 'px';
          this.defaultHeight = this.defaultNoResultsHeight;
          this.defaultWidth = this.defaultNoResultsWidth;
          this.dialogRef.updateSize(updatedWidthPixels, updatedHeightPixels);
        }
        this.caseResponse.next(next);
        this.participant = next?.participant;
        this.setDisabledAssessmentResults();
        this.checkForAutoSelectedAssessment(next.assessmentResults);
        this.isLoading = false;
      }, (error) => {
        swal.fire({
          heightAuto: false,
          confirmButtonColor: 'var(--primary-500)',
          icon: 'error',
          title: 'Error',
          text: 'Unable to submit. Please try again.'
        });
        this.isLoading = false;
        return;
      });
  }

  checkForAutoSelectedAssessment(assessmentResults: AssessmentResultCaseViewModel[]): void {
    if (this.data?.assessmentId) {
      const selectedAssessmentResult = assessmentResults.filter(x => x.id === this.data.assessmentId);
      if (selectedAssessmentResult && selectedAssessmentResult.some(x => !x.sentCaseDate)) {
        this.form.get('assessments').setValue(selectedAssessmentResult);
      }
      this.onAssessmentSelectionChange();
    }
  }

  isDisabled(assessmentResultId: string): boolean {
    const foundAssessment = this.disabledAssessmentResults?.value?.find(x => x === assessmentResultId);
    return !!foundAssessment;
  }

  getCounselorNames(): string {
    const values = this.form.get('counselors')?.value;
    const counselorValues: string[] = [];
    if (values) {
      values.forEach(value => {
        if (!counselorValues.find(x => x === value.counselorName)) {
          counselorValues.push(value.counselorName);
        }
      });
    }

    return counselorValues.join(', ');
  }

  setDisabledAssessmentResults(): void {
    const disableIds: string[] = [];
    this.caseResponse?.value?.assessmentResults?.forEach((item: AssessmentResultCaseViewModel) => {
      if (item?.sentCaseDate) {
        disableIds.push(item.id);
      }
    });

    if (disableIds.length > 0) {
      this.disabledAssessmentResults.next(disableIds);
    }
  }

  onAssessmentSelectionChange(): void {
    const numberOfAssessments = this.assessmentsResultCases?.length || 0;
    const height = this.defaultHeight + (50 * numberOfAssessments) + 'px';
    const width = this.defaultWidth + 'px';
    this.dialogRef.updateSize(width, height);
  }

  onCounselorSelect(counselorName: string, departmentId: string): void {
    this.counselorSelect.options.forEach((item: MatOption) => {
      if (item.value.counselorName === counselorName) {
        if (item.value.organizationId !== departmentId) {
          if (item.selected) {
            item.deselect();
          } else {
            item.select();
          }
        }
      }
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.isLoading = true;
    const branchId = this.participant.branchId;
    const branchName = BRANCH_OPTIONS.find(x => x.value === branchId).label;
    const caseViewModel = CreateCaseViewModel.fromJS({
      source: `${branchName} Referral`,
      sourceName: `${branchName} Referral`,
      comments: this.form?.get('comments')?.value,
      status: CaseStatus.Submitted,
      participant: ParticipantDataViewModel.fromJS(this.participant),
      counselorDepartments: this.form?.get('counselors')?.value?.map((counselor) =>
        CounselorDeptInformationViewModel.fromJS(counselor)),
      assessments: this.form?.get('assessments')?.value?.map((assessment) =>
        AssessmentResultCaseViewModel.fromJS(assessment))
    });

    this.caseClient.saveAssessmentsCase(SaveFrHcwCaseRequest.fromJS({
      case: caseViewModel,
      branchId
    })).subscribe((next) => {
      swal.fire({
        heightAuto: false,
        confirmButtonColor: 'var(--primary-500)',
        icon: 'success',
        title: 'Inquiry successfully submitted.'
      }).then(result => {
        this.isLoading = false;
        if (result) {
          this.dialogRef.close({
            numberOfAssessmentsSubmitted: this.assessmentsResultCases.length
          });
        }
      });
    }, (error) => {
      this.isLoading = false;
      swal.fire({
        heightAuto: false,
        confirmButtonColor: 'var(--primary-500)',
        icon: 'error',
        title: 'Error',
        text: 'Unable to add inquiry.'
      });
    });
  }

  getBranchText(): string {
    const userBranchName = this.authService.authUser$.getValue().branchName;
    return userBranchName === 'HealthcareWorkers' ? 'health care worker' : 'first responder';
  }
}
