<mat-drawer-container [hasBackdrop]="isHandset | async" [autosize]="false" class="set-height">
  <mat-drawer #drawer [mode]="(isHandset | async) ? 'over' : 'side'" [opened]="sidenavService.opened">
    <div class="d-flex flex-row justify-content-center">
      <img src="assets/images/aspen_logo.png" class="header-image" alt="ASPEN" matTooltip="ASPEN - Accessing Services Providing Essential Needs" matTooltipPosition="right" />
    </div>
    <app-sidenav-items></app-sidenav-items>
  </mat-drawer>
  <mat-drawer #drawer2 [mode]="'over'" position="end" [opened]="profileService.opened">
    <app-profile-items></app-profile-items>
  </mat-drawer>
  <mat-drawer-content>
    <ng-content></ng-content>
  </mat-drawer-content>
</mat-drawer-container>
