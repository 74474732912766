import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AuthRoutingModule} from './auth-routing.module';
import {AuthCallbackComponent} from './components/auth-callback/auth-callback.component';
import {ShouldLoginComponent} from './components/should-login/should-login.component';
import {AuthorizedDirective} from './directives/authorized.directive';
import {PermissionsDirective} from './directives/permissions.directive';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  imports: [CommonModule, AuthRoutingModule, MatCardModule, MatIconModule],
  declarations: [
    AuthCallbackComponent,
    ShouldLoginComponent,
    AuthorizedDirective,
    PermissionsDirective,
  ],
    exports: [
        AuthCallbackComponent,
        AuthorizedDirective,
        PermissionsDirective,
        ShouldLoginComponent,
    ],
  providers: [],
})
export class AuthModule {}
