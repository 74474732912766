<ng-container *ngIf="(authService.isDoneLoading$ | async) && (featureService.initialized$ | async)">

  <app-sidenav>
    <div class="set-height" [class.disable-clicks]="isActiveTour$ | async">
      <app-mobile-header *ngIf="isHandset | async"></app-mobile-header>
      <app-header *ngIf="!(isHandset | async)"></app-header>
      <router-outlet></router-outlet>
    </div>
  </app-sidenav>
</ng-container>
