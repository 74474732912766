import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '../../../../auth/services/auth.service';
import {Observable, Subscription} from 'rxjs';
import {Disposable} from '../../../../shared/extensions/disposable';
import {map, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent extends Disposable implements OnInit {
  @Input() lighter: boolean;
  private userInfoSub$: Subscription = null;
  constructor(
    private authService: AuthService) {
    super();
  }

  ngOnInit(): void {
  }

  public get isLoggedIn(): Observable<boolean> {
    return this.authService.isAuthenticated$;
  }

  public get welcomeMessage(): Observable<string> {
    return this.authService.authUser$.pipe(
      takeUntil(this.$destroyed),
      map(info => {
        return info?.displayName ? info?.displayName : info?.userName;
      })
    );
  }

}
