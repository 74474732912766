import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {MonitoringService} from './monitoring.service';

@Injectable()
export class MonitoringErrorHandler extends ErrorHandler {
  constructor(private injector: Injector) {
    super();
  }

  handleError(error: any): void {
    const monitorService: MonitoringService = this.injector.get(MonitoringService);

    if (monitorService) {
      monitorService.logError(error);
    }
    super.handleError(error);
  }
}
