<mat-toolbar>
  <mat-toolbar-row>
    <button
      (click)="sidenavService.toggle()"
      mat-icon-button
      matTooltip="Toggle Navigation"
      type="button"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <img src="assets/images/aspen_logo.png" class="header-image" alt="ASPEN" (click)="goToDashboard()" />
    <div class="ml-auto d-flex flex-row align-items-center">
      <button mat-icon-button [matMenuTriggerFor]="menu" class="mr-3 start-tour-button tour-counselor-final-thoughts-start-tour-button">
        <mat-icon>account_circle</mat-icon>
      </button>
      <button *ngIf="profileService.showProfileSwitcher$ | async" mat-icon-button (click)="profileService.toggle()" matTooltip="Switch Profile">
        <mat-icon>switch_access_shortcut</mat-icon>
      </button>
    </div>
  </mat-toolbar-row>

  <mat-toolbar-row *ngIf="showBottomToolbar$ | async" class="sub-header">
    <div class="ml-auto d-flex flex-row align-items-center">
      <div *appAuthorize="['participant']" class="d-flex align-items-center p-3">
        <button
          *ngIf="isFrOrHCW$ | async"
          (click)="openReachOutForAssistanceDialog()"
          class="start-tour-button"
          mat-stroked-button
          matTooltip="Find assistance"
        >
          <span *ngIf="!(isHandSet$ | async)" class="mr-1">{{assistanceButtonText}}</span><mat-icon class="pr-4 material-symbols-outlined">{{assistanceIcon}}</mat-icon>
        </button>
      </div>

      <div class="align-self-center tour-counselor-dashboard-student-search-icon" [class.mr-3]="!(profileService.showProfileSwitcher$ | async)" *ngIf="isLoggedIn | async">
        <ng-container *appAuthorize="[counselorRole, districtCounselorRole]">
          <app-quick-search></app-quick-search>
        </ng-container>
      </div>

      <div class="align-self-center" [class.mr-3]="!(profileService.showProfileSwitcher$ | async)" *ngIf="isLoggedIn | async">
        <ng-container *ngIf="(profileType$ | async) === admin">
          <app-admin-quick-search></app-admin-quick-search>
        </ng-container>
      </div>

      <div class="align-self-center" [class.mr-3]="!(profileService.showProfileSwitcher$ | async)" *ngIf="isLoggedIn | async">
        <ng-container *appAuthorize="[hotlineRole]">
          <app-hotline-quick-search></app-hotline-quick-search>
        </ng-container>
      </div>

    </div>
  </mat-toolbar-row>

</mat-toolbar>
<mat-menu #menu="matMenu">
  <div *ngIf="(isLoggedIn | async)" class="d-flex flex-column justify-content-center p-2 text-muted text-center">
    <span class="non-button-menu">{{(userSnapshot$ | async)?.displayName}}</span>
    <span class="sub-non-button-menu">{{(userSnapshot$ | async)?.role}}</span>
  </div>
  <button mat-menu-item (click)="navigateToDetail()" *ngIf="(isLoggedIn | async) && isParticipant">
    <mat-icon>account_circle</mat-icon>
    <span>Profile</span>
  </button>
  <button mat-menu-item (click)="startTour()" *appAuthorize="[counselorRole, districtCounselorRole]">
    <mat-icon>tour</mat-icon>
    <span>Start Tour</span>
  </button>
  <button mat-menu-item (click)="logout()" *ngIf="isLoggedIn | async">
    <mat-icon>logout</mat-icon>
    <span>Logout</span>
  </button>
  <button mat-menu-item (click)="login()" *ngIf="!(isLoggedIn | async)">
    <mat-icon>login</mat-icon>
    <span>Login</span>
  </button>
</mat-menu>

