<div class="d-flex flex-row align-items-center quick-search-wrapper">
  <button *ngIf="!expand" mat-icon-button matTooltip="Search Cases" (click)="expand = true">
    <mat-icon color="primary">search</mat-icon>
  </button>
  <div *ngIf="expand">
    <form>
      <mat-form-field appearance="outline">
        <input type="text"
               class="searchInput"
               label="Search cases"
               matInput
               placeholder="Student name or case ID"
               [formControl]="myControl"
               [matAutocomplete]="auto">
      </mat-form-field>
    </form>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="showDetail($event.option.value)" [displayWith]="getOptionName">
      <mat-option *ngFor="let option of (filteredOptions$ | async)" [value]="option">
        {{ option?.firstName + ' ' + option?.lastName + ' - ' + option?.caseNumber }}
      </mat-option>
    </mat-autocomplete>
  </div>
  <div *ngIf="expand">
    <button mat-icon-button matTooltip="Close Search" (click)="expand = false">
      <mat-icon color="primary">close</mat-icon>
    </button>
  </div>
</div>
