import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  private cachedHandsetState: Observable<BreakpointState>;
  private cachedTabletState: Observable<BreakpointState>;
  private cachedHandsetOrTabletState: Observable<BreakpointState>;

  constructor(
    private readonly breakpointObserver: BreakpointObserver
  ) {}

  getHandsetState(): Observable<BreakpointState> {
    if (!this.cachedHandsetState) {
      this.cachedHandsetState = this.breakpointObserver.observe(Breakpoints.Handset).pipe(shareReplay());
    }

    return this.cachedHandsetState;
  }

  getHandsetOrTabletState(): Observable<BreakpointState> {
    if (!this.cachedHandsetOrTabletState) {
      this.cachedHandsetOrTabletState = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet]).pipe(shareReplay());
    }

    return this.cachedHandsetOrTabletState;
  }

  getTabletState(): Observable<BreakpointState> {
    if (!this.cachedTabletState) {
      this.cachedTabletState = this.breakpointObserver.observe([Breakpoints.Tablet]).pipe(shareReplay());
    }

    return this.cachedTabletState;
  }

  get isHandset$(): Observable<boolean> {
    return this.getHandsetState().pipe(map(state => state.matches));
  }

  get isHandsetOrTablet$(): Observable<boolean> {
    return this.getHandsetOrTabletState().pipe(map(state => state.matches));
  }

  get isTablet$(): Observable<boolean> {
    return this.getTabletState().pipe(map(state => state.matches));
  }
}
