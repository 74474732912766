import {KeycloakService} from 'keycloak-angular';
import {Injector} from '@angular/core';
import {AuthService} from './services/auth.service';
import {ENV_CONFIG} from '../../configuration';

export function initializeKeycloak(keycloak: KeycloakService, injector: Injector, auth: AuthService): () => Promise<boolean> {
  const config = injector.get(ENV_CONFIG);
  auth.subscribeToEvents();
  return () =>
    keycloak.init({
      config: {
        url: config.authConfig.url,
        realm: config.authConfig.realm,
        clientId: config.authConfig.clientId
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
        scope: 'offline_access'
      },
      loadUserProfileAtStartUp: true
    });
}
