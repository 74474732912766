import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, mergeMap, takeUntil,} from 'rxjs/operators';
import {UntypedFormControl} from '@angular/forms';
import {CounselorViewModel, ParticipantClient, ParticipantDataViewModel,} from '../../../../shared/apis/api.client';
import {AuthService} from '../../../../auth/services/auth.service';
import {Disposable} from '../../../../shared/extensions/disposable';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss'],
})
export class QuickSearchComponent extends Disposable implements OnInit {
  myControl = new UntypedFormControl();
  filteredOptions$: Observable<ParticipantDataViewModel[]>;
  options;
  expand = false;
  counselorDataSubject = new BehaviorSubject<CounselorViewModel>(null);
  counselorData$ = this.counselorDataSubject.asObservable();

  constructor(
    private participantClient: ParticipantClient,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute) {
    super();
    this.auth.authUser$.pipe(takeUntil(this.$destroyed)).subscribe((user) => {
      this.counselorDataSubject.next(user?.counselor);
    });
  }

  ngOnInit(): void {
    this.filteredOptions$ = this.getParticipants('');
    this.myControl.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(300))
      .subscribe((value) => {
        this.filteredOptions$ = this.getParticipants(value);
      });
  }

  getParticipants(searchText: string): Observable<ParticipantDataViewModel[]> {
    return this.counselorData$.pipe(
      takeUntil(this.$destroyed),
      filter(counselorData => !!counselorData),
      mergeMap(counselorData => {
        const organizationIds = counselorData?.organizations.map(x => {
          return x.organizationId;
        });

        const districtIds = counselorData?.organizations.map(x => {
          return x.districtId;
        });

        return this.participantClient.quickSearch(searchText, organizationIds, districtIds);
      }),
      map((result) => result.participants)
    );
  }

  getOptionName(option: ParticipantDataViewModel): string {
    if (option) {
      return option?.user ? `${option?.user.firstName} ${option?.user.lastName}` : '';
    } else {
      return '';
    }
  }

  goToDetails(option: ParticipantDataViewModel): void {
    if (option) {
      this.router.navigate(['schools', 'profile', 'student-detail'], {
        relativeTo: this.route,
        queryParams: { id: option.id },
      });
    }
  }
}
