import {Inject, Injectable} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from './services/auth.service';
import {ENV_CONFIG, EnvConfig} from '../../configuration';
import {filter, mergeMap, take} from 'rxjs/operators';
import {BranchClient, ProfileType} from '../shared/apis/api.client';
import {KeycloakService} from 'keycloak-angular';

@Injectable()
export class BranchInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private branchClient: BranchClient,
    private kcService: KeycloakService,
    @Inject(ENV_CONFIG) private envConfig: EnvConfig
  ) {}

  private pathsToIgnore = ['getuserbranchids', 'getauthuser', '/public/', '/register', '/featureflags'];

  private checkUrl(url: string): boolean {
    return url.startsWith(this.envConfig.appConfig.apiBaseUrl);
  }

  private addProfileIfExists(req: HttpRequest<unknown>): HttpRequest<unknown> {
    const profile = localStorage.getItem('currentProfile');
    if (profile && profile !== 'undefined' && profile !== '') {
      const headers = req.headers.set('x-profile-type', ProfileType[profile]);
      req = req.clone({ headers });
    }
    return req;
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = req.url.toLowerCase();

    if (!this.checkUrl(url)) {
      return next.handle(req);
    }

    const token = this.kcService.getKeycloakInstance()?.token;
    if (!token) {
      return next.handle(req);
    }

    const branchIds = this.authService.authUser?.branchIds;
    req = this.addProfileIfExists(req);
    if (branchIds) {
      const branchString = branchIds?.join(',');
      const headers = req.headers.set('x-branches', branchString);
      req = req.clone({ headers });
    } else {
      return !this.pathsToIgnore.some((p) => url.includes(p))
        ? this.authService.authUser$.pipe(
            // wait for the auth service to get the auth user and branch id's before completing the request
            filter((x) => !!x?.branchIds),
            take(1),
            mergeMap((x) => {
              const branchString = x.branchIds?.join(',');
              const headers = req.headers.set('x-branches', branchString);
              req = req.clone({ headers });
              return next.handle(req);
            })
          )
        : next.handle(req.clone());
    }
    return next.handle(req);
  }
}

export const BranchInterceptorClass = {
  provide: HTTP_INTERCEPTORS,
  useClass: BranchInterceptor,
  multi: true,
};
