import {InjectionToken} from '@angular/core';

export interface AppInsightsConfig {
  instrumentationKey: string;
}

export interface AppConfig {
  apiBaseUrl: string;
  baseUrl: string;
  storageKeyPrefix: string;
  mapbox: {
    accessToken: string;
    defaultMapCenter: { lng: number; lat: number };
  };
  waiverJotformPropertyMap: WaiverJotformPropertyMap;
  possibleFileTypes: [];
  registrationUrl: string;
  contactInfo: ContactInfo;
}

export interface ContactInfo {
  infoEmail: string;
  infoPhone: string;
}

export interface EnvConfig {
  sessionTimeout: number;
  siteName: string;
  appConfig: AppConfig;
  authConfig: AuthConfig;
  appInsights: AppInsightsConfig;
  production: boolean;
}

export interface AuthConfig {
  url: string;
  realm: string;
  clientId: string;
}

interface WaiverJotformPropertyMap {
  topSignatureBlock: {
    signature1: string;
    signature2: string;
    signature3: string;
    signature4: string;
  };
  studentName: {
    first: string;
    last: string;
  };
  studentDateOfBirth: {
    month: string;
    day: string;
    year: string;
  };
  legacyDistrictSchoolName: string;
  districtSchoolName: string;
  parentGuardianName: {
    first: string;
    last: string;
  };
  studentEmail: string;
  parentGuardianSignature: string;
  dateSigned: {
    month: string;
    day: string;
    year: string;
  };
  declined: string;
}

export const ENV_CONFIG = new InjectionToken<EnvConfig>(
  '@@jchnEnvironmentConfiguration'
);
