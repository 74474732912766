import {Component, OnInit} from '@angular/core';
import {ProfileService} from '../../../../shared/services/profile.service';
import {ProfileType} from "../../../../shared/apis/api.client";
import {Disposable} from "../../../../shared/extensions/disposable";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-profile-items',
  templateUrl: './profile-picker-items.component.html',
  styleUrls: ['./profile-picker-items.component.scss']
})
export class ProfilePickerItemsComponent extends Disposable implements OnInit {

  public selected: ProfileType[];

  constructor(public profileService: ProfileService) {
    super();
  }


  ngOnInit(): void {
    this.profileService.drawerToggled$.pipe(takeUntil(this.$destroyed)).subscribe(opened => {
      if (!opened) {
        this.selected = [];
      }
    });
  }

  public confirmSwitch(): void {
    if (this.selected && this.selected.length) {
      this.profileService.switchProfile(this.selected[0]);
    }
  }

  public cancel(): void {
    this.profileService.toggle();
  }

  public getProfileIcon(type: ProfileType): string {
    return ProfileService.getProfileIcon(type);
  }

}
