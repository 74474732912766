import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {AuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloakService: KeycloakService,
    protected readonly authService: AuthService,
  ) {
    super(router, keycloakService);
  }

  public isAccessAllowed = async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) => {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.authService.login(state.url);
    }

    return this.authenticated;
  }
}
