import {CounselorViewModel, ParticipantDataViewModel, ProfileType} from '../../shared/apis/api.client';
import {KeycloakProfile} from "keycloak-js";

export class AuthUser {
  email: string;
  userId: string;
  userName: string;
  firstName: string;
  lastName: string;
  displayName: string;
  roles: string[];
  permissions: string[];
  counselor: CounselorViewModel;
  participant: ParticipantDataViewModel;
  profileCompleted: boolean;
  waiverUploaded: boolean;
  branchIds: string[];
  branchName: string;
  profileType?: ProfileType;
  userProfiles: ProfileType[];
  inDatabase: boolean;

  constructor(data?: any) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          (this as any)[property] = (data as any)[property];
        }
      }
    }
  }

  static buildAuthUser(identityClaims: any): AuthUser {
    const user = new AuthUser();
    if (!identityClaims) {
      return user;
    }
    user.userId = identityClaims.sub;
    user.userName = identityClaims.email;
    user.email = identityClaims.email;

    const first = identityClaims.given_name ? identityClaims.given_name : '';
    const last = identityClaims.family_name ? identityClaims.family_name : '';

    user.displayName = `${first} ${last}`;
    user.displayName = !user.displayName.trim() ? identityClaims.name : user.displayName;
    user.firstName = first?.trim();
    user.lastName = last?.trim();
    user.roles = AuthUser._buildRoles(identityClaims);
    user.profileCompleted = identityClaims.profile_completed;
    user.waiverUploaded = identityClaims.waiver_uploaded;
    user.userProfiles = [];

    return user;
  }

  static buildAuthUserFromKcProfile(profile: KeycloakProfile): AuthUser {
    const user = new AuthUser();
    if (!profile) {
      return user;
    }
    user.userId = profile.id;
    user.userName = profile.email;
    user.email = profile.email;

    user.displayName = `${profile.firstName} ${profile.lastName}`;
    user.displayName = !user.displayName.trim() ? profile.email : user.displayName;
    user.firstName = profile.firstName?.trim();
    user.lastName = profile.lastName?.trim();
    user.userProfiles = [];

    return user;
  }

  private static _buildRoles(identityClaims: any): string[] {
    if (identityClaims.role) {
      return identityClaims.role instanceof Array ? identityClaims.role : [identityClaims.role];
    }
    return [];
  }
}
