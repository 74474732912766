import {Component, OnInit} from '@angular/core';
import {MonitoringService} from './shared/services/monitoring.service';
import {FeatureService} from './shared/feature-flags/feature.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'aspen-dashboard';

  constructor(
    private readonly monitoringService: MonitoringService,
    public featureService: FeatureService
  ) {
    this.featureService.initFeatureManagement();
  }

  ngOnInit(): void {
    this.monitoringService.setUpRouteLogging();
  }
}
