import {InjectionToken} from '@angular/core';

export const AuthorityUrl = new InjectionToken<string>('AuthorityUrl');

export enum TourSessionStorage {
  CounselorTour = 'tours.counselor'
}

export enum TourStatus {
  Complete = 'complete',
  Incomplete = 'incomplete'
}
