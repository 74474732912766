import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription, timer} from 'rxjs';
import {mergeMap, tap} from 'rxjs/operators';
import {Feature, FeatureFlagsClient} from '../apis/api.client';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  private refreshTimer = 60000;
  private featuresSubscription: Subscription;
  public featuresList$: BehaviorSubject<Feature[]> = new BehaviorSubject<Feature[]>([]);
  public initialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private featureFlagsClient: FeatureFlagsClient) {}

  public featureEnabled(featureName: string): boolean {
    let enabled: boolean;
    const list = this.featuresList$.getValue();
    enabled = list.some((f) => f.name.toLowerCase() === featureName.toLowerCase() && f.enabled === true);
    return enabled;
  }

  public initFeatureManagement(): void {
    if (!this.featuresSubscription) {
      const timer$ = timer(0, this.refreshTimer);

      this.featuresSubscription = timer$
        .pipe(
          mergeMap((_) => this.featureFlagsClient.get()),
          tap((features: Feature[]) => {
            this.featuresList$.next(features || []);
            this.initialized$.next(true);
          })
        )
        .subscribe();
    }
  }

  hasAllFeaturesEnabled(...features: string[]): boolean {
    if (!this.featuresSubscription) {
      return false;
    }
    if (!features?.length) {
      return false;
    }
    features = features.map((f) => f.toLowerCase());
    const featureList = this.featuresList$.getValue();
    if (!featureList?.length) {
      return false;
    }
    const enabledFeatures = featureList.filter((f) => f.enabled === true).map((f) => f.name.toLowerCase());
    if (enabledFeatures.length === 0) {
      return false;
    }
    let hasAll: boolean;
    hasAll = features.every((f) => enabledFeatures.includes(f));

    return hasAll;
  }
}
