import {Component, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {CaseClient, SchoolCaseViewModel} from '../../../../shared/apis/api.client';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-hotline-quick-search',
  templateUrl: './hotline-quick-search.component.html',
  styleUrls: ['./hotline-quick-search.component.scss'],
})
export class HotlineQuickSearchComponent implements OnInit {
  option: SchoolCaseViewModel;
  myControl = new UntypedFormControl();
  filteredOptions$: Observable<SchoolCaseViewModel[]>;
  expand = false;
  placeholder: string;
  id: string;

  constructor(private caseClient: CaseClient, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.filteredOptions$ = this.getCases('');
    this.myControl.valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe((value) => {
      this.filteredOptions$ = this.getCases(value);
    });
  }

  getCases(searchText: string): Observable<SchoolCaseViewModel[]> {
    return this.caseClient.quickSearchCases(searchText, 4).pipe(map((result) => result.cases));
  }

  showDetail(selectedCase: any): void {
    this.router
      .navigate(['/'])
      .then(_ => {
        this.router
          .navigate(['app', 'schools', 'case', 'detail', selectedCase.id], {
            relativeTo: this.route.root,
          });
        }
      );
  }

  getOptionName(option: SchoolCaseViewModel): string {
    if (option) {
      return option.firstName + ' ' + option.lastName + ' - ' + option.caseNumber;
    } else {
      return '';
    }
  }
}
