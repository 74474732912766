import {Component} from '@angular/core';
import {BaseHeaderComponent} from '../shared/base-header.component';
import {AuthService} from '../../../auth/services/auth.service';
import {SidenavService} from '../sidenav/sidenav.service';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {ProfileService} from '../../../shared/services/profile.service';
import {ParticipantClient} from '../../../shared/apis/api.client';
import {TourService} from '../../../shared/services/tour.service';
import {BreakpointService} from '../../../shared/services/breakpoint.service';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
})
export class MobileHeaderComponent extends BaseHeaderComponent {

  constructor(
    authService: AuthService,
    route: ActivatedRoute,
    breakpointService: BreakpointService,
    router: Router,
    participantClient: ParticipantClient,
    sidenavService: SidenavService,
    tourService: TourService,
    dialog: MatDialog,
    profileService: ProfileService
  ) {
    super(
      authService,
      route,
      breakpointService,
      router,
      participantClient,
      sidenavService,
      tourService,
      dialog,
      profileService
    );
  }

}
