import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from './layout/components/page-not-found/page-not-found.component';
import {AuthenticationGuard} from './auth/guards/authentication.guard';
import {BaseLayoutComponent} from './layout/components/base-layout/base-layout.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'app',
  },
  {
    path: 'app',
    component: BaseLayoutComponent,
    loadChildren: () =>
      import('./layout/components/base-layout/base-layout.module').then((module) => module.BaseLayoutModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'signin-oidc',
    redirectTo: 'auth/signin-oidc',
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((module) => module.AuthModule),
  },
  {
    path: 'public',
    loadChildren: () => import('./public/layout/layout.module').then((module) => module.LayoutModule),
  },
  {
    path: 'resource-directory',
    pathMatch: 'full',
    redirectTo: '/public/resource-directory',
  },
  {
    path: 'first-responders/public/welcome',
    pathMatch: 'full',
    redirectTo: '/public/screening/welcome',
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy', enableTracing: false})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
