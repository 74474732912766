import {Component, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {ProviderClient, ProviderPartnerViewModel,} from '../../../../shared/apis/api.client';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-admin-quick-search',
  templateUrl: './admin-quick-search.component.html',
  styleUrls: ['./admin-quick-search.component.scss']
})
export class AdminQuickSearchComponent implements OnInit {
  option: ProviderPartnerViewModel;
  myControl = new UntypedFormControl();
  filteredOptions$: Observable<ProviderPartnerViewModel[]>;
  expand = false;
  placeholder: string;
  id: string;

  constructor(private providerClient: ProviderClient,
              private router: Router,
              private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.filteredOptions$ = this.getProvidersAndPartners('');
    this.myControl.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(300))
      .subscribe(value => {
        this.filteredOptions$ = this.getProvidersAndPartners(value);
      });
  }

  getProvidersAndPartners(searchText: string): Observable<ProviderPartnerViewModel[]> {
    return this.providerClient.quickSearchAdmin(searchText, 4).pipe(
      map(result => result.providersAndPartners)
    );
  }

  goToDetails(option: ProviderPartnerViewModel): void{
    if (option?.isProvider){
      this.router.navigate(['admin', 'provider-detail'], {
        relativeTo: this.route,
        queryParams: {id: option.id},
      });
    }
    else if (option?.isProvider === false) {
      this.router.navigate(['admin', 'partner-detail'], {
        relativeTo: this.route,
        queryParams: {id: option.id},
      });
    }
  }

  getOptionName(option: ProviderPartnerViewModel): string{
    return option?.name;
  }

}
