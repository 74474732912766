<mat-toolbar>
  <button
    (click)="sidenavService.toggle()"
    mat-icon-button
    matTooltip="Toggle Navigation"
    type="button"
  >
    <mat-icon *ngIf="sidenavService.opened" color="primary">chevron_left</mat-icon>
    <mat-icon *ngIf="!sidenavService.opened" color="primary">chevron_right</mat-icon>
  </button>

  <div class="ml-auto d-flex flex-row align-items-center">
    <div *appAuthorize="['participant']" class="d-flex align-items-center pl-2 p-md-3">
      <button
        *ngIf="showReachOutButton"
        (click)="openReachOutForAssistanceDialog()"
        class="start-tour-button"
        mat-stroked-button
        matTooltip="Find assistance"
        color="primary">
        <span *ngIf="!(isHandSet$ | async)" class="mr-1">{{assistanceButtonText}}</span><mat-icon class="pr-4 material-symbols-outlined">{{assistanceIcon}}</mat-icon>
      </button>
    </div>
    <div class="tour-counselor-dashboard-add-student-top-nav">
      <ng-container  *appAuthorize="[counselorRole, districtCounselorRole]">
        <button mat-icon-button (click)="onAddStudent()" color="primary" id="add-student-icon" matTooltip="Add Student">
          <mat-icon>person_add</mat-icon>
        </button>
      </ng-container>
    </div>
    <div class="align-self-center tour-counselor-dashboard-student-search-icon" *ngIf="isLoggedIn | async">
      <ng-container *appAuthorize="[counselorRole, districtCounselorRole]">
        <app-quick-search></app-quick-search>
      </ng-container>
    </div>
    <div class="align-self-center" *ngIf="isLoggedIn | async">
      <ng-container *appAuthorize="['admin']">
        <app-admin-quick-search></app-admin-quick-search>
      </ng-container>
    </div>
    <div class="align-self-center" *ngIf="isLoggedIn | async">
      <ng-container *appAuthorize="[hotlineRole]">
        <app-hotline-quick-search></app-hotline-quick-search>
      </ng-container>
    </div>
    <app-user-info
      [lighter]="true"
      class="align-self-center"
      *ngIf="(isLoggedIn | async) && !(isHandSet$ | async)"
    ></app-user-info>
    <button mat-icon-button color="primary" [matMenuTriggerFor]="menu" class="ml-md-2 start-tour-button tour-counselor-final-thoughts-start-tour-button">
      <mat-icon>account_circle</mat-icon>
    </button>
    <button *ngIf="profileService.showProfileSwitcher$ | async" color="primary" mat-icon-button (click)="profileService.toggle()" class="ml-1" matTooltip="Switch Profile">
      <mat-icon>switch_access_shortcut</mat-icon>
    </button>
  </div>
</mat-toolbar>
<mat-menu #menu="matMenu">
  <div *ngIf="(isLoggedIn | async)" class="d-flex flex-column justify-content-center p-2 text-muted text-center">
    <span class="non-button-menu">{{(userSnapshot$ | async)?.displayName}}</span>
    <span class="sub-non-button-menu">{{(userSnapshot$ | async)?.role}}</span>
  </div>
  <button mat-menu-item (click)="navigateToDetail()" *ngIf="(isLoggedIn | async) && isParticipant">
    <mat-icon>account_circle</mat-icon>
    <span>Profile</span>
  </button>
  <button mat-menu-item (click)="startTour()" *appAuthorize="[counselorRole, districtCounselorRole]">
    <mat-icon>tour</mat-icon>
    <span>Start Tour</span>
  </button>
  <button mat-menu-item (click)="logout()" *ngIf="isLoggedIn | async">
    <mat-icon>logout</mat-icon>
    <span>Logout</span>
  </button>
  <button mat-menu-item (click)="login()" *ngIf="!(isLoggedIn | async)">
    <mat-icon>login</mat-icon>
    <span>Login</span>
  </button>
  <button mat-menu-item (click)="contactSupport()">
    <mat-icon>contact_support</mat-icon>
    <span>Contact ASPEN</span></button>
</mat-menu>

