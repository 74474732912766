<div class="d-flex p-3 justify-content-center text-muted">
  <h4 class="primary-color">Select a Profile</h4>
</div>
<mat-selection-list class="mb-3 min-width" [multiple]="false" [(ngModel)]="selected">
  <mat-list-option [disabled]="profileItem.isActive" *ngFor="let profileItem of profileService.profileSelections$ | async" [value]="profileItem.type">
    <div class="d-flex flex-column">
      <div class="d-flex flex-row">
        <mat-icon class="mr-1 align-self-center">{{getProfileIcon(profileItem.type)}}</mat-icon>
        <span>{{profileItem.name}}</span>
      </div>
      <span class="pl-5 font-italic profile-sub-text" *ngIf="profileItem.isActive">(Currently Selected)</span>
    </div>
  </mat-list-option>
</mat-selection-list>
<div class="d-flex flex-column justify-content-center p-2">
  <button mat-stroked-button class="w-100 mb-1" [autofocus]="true" color="primary" (click)="confirmSwitch()"><mat-icon class="mr-2 align-self-center">switch_account</mat-icon>Switch</button>
  <button mat-stroked-button class="w-100" color="primary" (click)="cancel()"><mat-icon class="mr-2 align-self-center">cancel</mat-icon>Cancel</button>
</div>


