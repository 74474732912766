import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {BreakpointService} from '../../../shared/services/breakpoint.service';
import {AuthService} from '../../../auth/services/auth.service';
import {SidenavService} from './sidenav.service';
import {ProfileService} from "../../../shared/services/profile.service";

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private breakpointService: BreakpointService,
    public sidenavService: SidenavService,
    public profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.closeSidenavWhenDeviceIsHandset();
  }

  public get isLoggedIn(): Observable<boolean> {
    return this.authService.isAuthenticated$;
  }

  public get isHandset(): Observable<boolean> {
    return this.breakpointService.isHandset$;
  }

  private closeSidenavWhenDeviceIsHandset(): void {
    this.breakpointService.isHandset$.subscribe((isHandset) => {
      this.sidenavService.opened = !isHandset;
    });
  }
}
