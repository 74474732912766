import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../auth/services/auth.service';
import {FeatureService} from '../../../shared/feature-flags/feature.service';
import {BreakpointService} from "../../../shared/services/breakpoint.service";
import {Observable} from "rxjs";
import {TourService} from "../../../shared/services/tour.service";

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss']
})
export class BaseLayoutComponent implements OnInit {
  public initializing = true;

  constructor(
    public router: Router,
    public authService: AuthService,
    public featureService: FeatureService,
    private breakPointService: BreakpointService,
    private tourService: TourService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
  }

  public get isHandset(): Observable<boolean> {
    return this.breakPointService.isHandset$;
  }

  public get isLoggedIn(): Observable<boolean> {
    return this.authService.isAuthenticated$;
  }

  get isActiveTour$(): Observable<boolean> {
    return this.tourService.isActiveTour$;
  }

}
