export enum RoleName {
  Admin = 'admin',
  DistrictCounselor = 'district_counselor',
  Counselor = 'counselor',
  Participant = 'participant',
  FirstResponderChaplain = 'first_responder_chaplain',
  MentalHealthProvider = 'mental_health_provider',
  HotlineSupport = 'hotline_support',
  IncidentResponseUser = 'incident_response_user',
  IncidentResponseAdmin = 'incident_response_admin',
  IncidentResponseSpecialistResource = 'incident_response_specialist',
  HealthcareWorkerChaplain = 'healthcare_worker_chaplain',
  HealthcareWorkerMhp = 'healthcare_worker_mhp',
  SchoolAdmin = 'school_admin',
}

export enum RoleFormattedName {
  Admin = 'Admin',
  DistrictCounselor = 'District School Staff',
  Counselor = 'School Staff',
  Participant = 'Participant',
  FirstResponderChaplain = 'First Responder Chaplain',
  MentalHealthProvider = 'Mental Health Provider',
  HotlineSupport = 'Hotline Support',
  IncidentResponseUser = 'Incident Response User',
  IncidentResponseAdmin = 'Incident Response Admin',
  IncidentResponseSpecialistResource = 'Incident Response Specialized Resource',
  HealthcareWorkerChaplain = 'Healthcare Worker Chaplain',
  HealthcareWorkerMhp = 'Healthcare Worker MHP',
  SchoolAdmin = 'School Admin',
}

export const getFormattedRoleName = (role: string) => {
  switch (role as RoleName) {
    case RoleName.Admin:
      return RoleFormattedName.Admin;
    case RoleName.DistrictCounselor:
      return RoleFormattedName.DistrictCounselor;
    case RoleName.Counselor:
      return RoleFormattedName.Counselor;
    case RoleName.Participant:
      return RoleFormattedName.Participant;
    case RoleName.FirstResponderChaplain:
      return RoleFormattedName.FirstResponderChaplain;
    case RoleName.MentalHealthProvider:
      return RoleFormattedName.MentalHealthProvider;
    case RoleName.HotlineSupport:
      return RoleFormattedName.HotlineSupport;
    case RoleName.IncidentResponseUser:
      return RoleFormattedName.IncidentResponseUser;
    case RoleName.IncidentResponseAdmin:
      return RoleFormattedName.IncidentResponseAdmin;
    case RoleName.IncidentResponseSpecialistResource:
      return RoleFormattedName.IncidentResponseSpecialistResource;
    case RoleName.HealthcareWorkerChaplain:
      return RoleFormattedName.HealthcareWorkerChaplain;
    case RoleName.HealthcareWorkerMhp:
      return RoleFormattedName.HealthcareWorkerMhp;
    case RoleName.SchoolAdmin:
      return RoleFormattedName.SchoolAdmin;
  }
};
