import {Component, Inject} from '@angular/core';
import {AppConstants} from '../../../shared/constants';
import {Router} from '@angular/router';
import {ENV_CONFIG, EnvConfig} from '../../../../configuration';

@Component({
  selector: 'app-should-login',
  template: `
    <div class="container">
      <div class="pt-5 row justify-content-center">
        <div class="col-md-8 col-lg-6">
          <mat-card>
            <mat-card-title>
              <div class="d-flex">
                <mat-icon class="align-self-center mr-1">gpp_maybe</mat-icon>
                Unauthorized
              </div>
            </mat-card-title>
            <mat-card-content>
              <p class="alert alert-dark">You need to be logged in as an authorized {{roleName}} to view the requested page.</p>
              <p>Please <a href="mailto:{{supportEmail}}">contact an administrator</a> for assistance.</p>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  `,
})
export class ShouldLoginComponent {
  roleName: string;

  supportEmail = '';

  constructor(
    private router: Router,
    @Inject(ENV_CONFIG) private readonly envConfig: EnvConfig
              ) {
    this.roleName = this.router.getCurrentNavigation()?.extras.state?.roleName || 'user';
    this.supportEmail = this.envConfig.appConfig.contactInfo?.infoEmail || AppConstants.SupportEmailAddress;
  }
}
