import {Directive, OnInit} from '@angular/core';
import {Disposable} from '../../../shared/extensions/disposable';
import {BehaviorSubject, Observable} from 'rxjs';
import {AuthService} from '../../../auth/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BreakpointService} from '../../../shared/services/breakpoint.service';
import {BranchName, ParticipantClient, ProfileType} from '../../../shared/apis/api.client';
import {takeUntil} from 'rxjs/operators';
import {SidenavService} from '../sidenav/sidenav.service';
import {TourService} from '../../../shared/services/tour.service';
import {getFormattedRoleName, RoleName} from '../../../shared/enums/role';
import {Branches} from '../../../shared/constants';
import {MatDialog} from '@angular/material/dialog';
import {
  ReachOutForAssistanceComponent
} from '../../../shared/modules/fr-hcw/reach-out-for-assistance/reach-out-for-assistance.component';
import {ProfileService} from '../../../shared/services/profile.service';

export interface UserSnapshot {
  displayName: string;
  role: string;
}

@Directive()
export abstract class BaseHeaderComponent extends Disposable implements OnInit {

  private participantProfileComplete = true;
  public isHandSet$: Observable<boolean>;
  public participantUserId = '';
  public isParticipant = false;
  public assistanceButtonText = '';
  public assistanceIcon = 'send';
  public branchIds;
  public counselorRole = RoleName.Counselor;
  public districtCounselorRole = RoleName.DistrictCounselor;
  public hotlineRole = RoleName.HotlineSupport;
  public profileType$ = new BehaviorSubject<ProfileType>(undefined);
  public isFrOrHCW$ = new BehaviorSubject<boolean>(false);
  public showBottomToolbar$ = new BehaviorSubject<boolean>(false);
  public userSnapshot$ = new BehaviorSubject<UserSnapshot>(undefined);

  public schools = ProfileType.Schools;
  public admin = ProfileType.Admin;
  public fr = ProfileType.FirstResponders;
  public ir = ProfileType.IncidentResponse;

  protected constructor(
    private authService: AuthService,
    private readonly route: ActivatedRoute,
    private breakpointService: BreakpointService,
    private router: Router,
    private participantClient: ParticipantClient,
    public sidenavService: SidenavService,
    private tourService: TourService,
    public dialog: MatDialog,
    public profileService: ProfileService
  ) {
    super();
    this.authService.authUser$.pipe(takeUntil(this.$destroyed)).subscribe((user) => {
      if (user) {
        this.branchIds = user.branchIds;
        this.participantProfileComplete = user.profileCompleted;
        this.userSnapshot$.next(
          {
            displayName: user.email ? user.email : user.displayName,
            role: getFormattedRoleName(user.roles[0])
          });
      }
      this.profileType$.next(user?.profileType);
      this.showBottomToolbar$.next((!user?.roles.includes(RoleName.Participant)) && user?.profileType !== ProfileType.IncidentResponse);
      const isFr = user?.profileType === ProfileType.HealthcareWorkers || user?.profileType === ProfileType.FirstResponders;
      this.isFrOrHCW$.next(isFr);
    });
  }

  public get isLoggedIn(): Observable<boolean> {
    return this.authService.isAuthenticated$;
  }

  public get showReachOutButton(): boolean {
    return this.isFirstRespondersBranchOrHcwBranch() && this.participantProfileComplete;
  }

  public ngOnInit(): void {
    this.isHandSet$ = this.breakpointService.isHandset$;
    this.isHandSet$.pipe(takeUntil(this.$destroyed)).subscribe(handset => {
      this.assistanceButtonText = handset ? '' : 'Need Assistance?';
      this.assistanceIcon = handset ? 'live_help' : 'send';
    });

  }

  public logout(): void {
    const url = this.route.snapshot.url.map((s) => s.path).join('/');
    this.authService.logout(url);
  }

  public login(): void {
    this.authService.login();
  }

  public switchProfile(type: ProfileType): void {
    localStorage.setItem('currentProfile', type);
    window.location.reload();
  }

  public navigateToDetail(): void {
    this.participantClient.getByUserId(this.participantUserId).subscribe((result) => {
      if (result) {
        this.router.navigate(['profile', 'student-detail'], {
          queryParams: { id: result.participant?.id },
        });
      }
    });
  }

  public startTour(): void {
    this.tourService.startCounselorTour();
  }

  public onAddStudent(): void {
    this.router.navigateByUrl('app/schools/add-participant').then();
  }

  public goToDashboard(): void {
    this.router.navigateByUrl('/').then();
  }

  public isFirstRespondersBranchOrHcwBranch(): boolean {
    const frBranchId = Branches.find((x) => x.name === BranchName.FirstResponders)?.id;

    const frBranch = this.branchIds?.find((branch) => branch === frBranchId);

    const hcwBranchId = Branches.find((x) => x.name === BranchName.HealthcareWorkers)?.id;

    const hcwBranch = this.branchIds?.find((branch) => branch === hcwBranchId);

    return frBranch || hcwBranch;
  }

  public openReachOutForAssistanceDialog(): void {
    this.dialog.open(ReachOutForAssistanceComponent, {
      height: '625px',
      width: '800px',
      panelClass: 'mat-dialog-panel-p-0',
    });
  }
}
