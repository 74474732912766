import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from '../services/auth.service';

@Directive({
  selector: '[appPermission]',
})
export class PermissionsDirective {
  constructor(
    private readonly authService: AuthService,
    private readonly viewRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>,
  ) {}

  @Input()
  set appPermission(permissions: string[]) {
    if (!permissions?.length) {
      this.viewRef.createEmbeddedView(this.templateRef);
      return;
    }
  }
}
