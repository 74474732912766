import {Component, OnInit} from '@angular/core';
import {SidenavService} from '../sidenav.service';

@Component({
  selector: 'app-sidenav-items',
  templateUrl: './sidenav-items.component.html',
  styleUrls: ['./sidenav-items.component.scss']
})
export class SidenavItemsComponent implements OnInit {

  constructor(public sidenavService: SidenavService) { }


  ngOnInit(): void {
  }

}
