import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/services/auth.service';
import { Disposable } from '../extensions/disposable';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { ProfileSelection } from '../interfaces/profile-selection';
import { ProfileType } from '../apis/api.client';

// Reporting is currently utilizing school branch locations. Will need to change branchId in future if reporting expands past school domain
const typesAndBranches = [
  {
    profileType: ProfileType.Schools,
    branchId: '62b4c7f143fd34a6f90d7c6b',
  },
  {
    profileType: ProfileType.FirstResponders,
    branchId: '62b4c7f143fd34a6f90d7c6c',
  },
  {
    profileType: ProfileType.HealthcareWorkers,
    branchId: '636a4b9e985a36277e961297',
  },
  {
    profileType: ProfileType.Reporting,
    branchId: '62b4c7f143fd34a6f90d7c6b',
  },
];

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends Disposable {
  private profileSelectionsSubject = new BehaviorSubject<ProfileSelection[]>([]);
  public profileSelections$ = this.profileSelectionsSubject.asObservable();
  private drawerToggledSubject = new Subject<boolean>();
  public drawerToggled$ = this.drawerToggledSubject.asObservable();
  private showProfileSwitcherSubject = new BehaviorSubject<boolean>(false);
  public showProfileSwitcher$ = this.showProfileSwitcherSubject.asObservable();
  public opened = false;

  constructor(private authService: AuthService) {
    super();
    this.authService.authUser$.pipe(takeUntil(this.$destroyed)).subscribe((user) => {
      this.showProfileSwitcherSubject.next(!!user.userProfiles?.length && user.userProfiles?.length > 1);
      if (!user || !user.userProfiles?.length) {
        this.profileSelectionsSubject.next([]);
      }
      const selections = user?.userProfiles.map((x) => {
        return {
          name: ProfileService.getNameFromType(x),
          role: user.roles[0],
          type: x,
          isActive: x === user.profileType,
        };
      });
      this.profileSelectionsSubject.next(selections);
    });
  }

  public static getNameFromType(type: ProfileType): string {
    switch (type) {
      case ProfileType.Admin:
        return 'Admin';
      case ProfileType.FirstResponders:
        return 'First Responders';
      case ProfileType.HealthcareWorkers:
        return 'Healthcare Workers';
      case ProfileType.IncidentResponse:
        return 'Incident Response Portal';
      case ProfileType.Schools:
        return 'Schools';
      case ProfileType.Reporting:
        return 'Reporting';
    }
  }

  public toggle(): void {
    this.opened = !this.opened;
    this.drawerToggledSubject.next(this.opened);
  }

  public switchProfile(type: ProfileType): void {
    localStorage.setItem('currentProfile', type);
    window.location.reload();
  }

  public static getProfileIcon(type: ProfileType): string {
    switch (type) {
      case ProfileType.Admin:
        return 'admin_panel_settings';
      case ProfileType.FirstResponders:
        return 'local_police';
      case ProfileType.HealthcareWorkers:
        return 'local_hospital';
      case ProfileType.IncidentResponse:
        return 'emergency';
      case ProfileType.Schools:
        return 'school';
      case ProfileType.Reporting:
        return 'description';
    }
  }

  public static getBranchIdFromProfileType(type: ProfileType): string {
    return typesAndBranches.find((x) => x.profileType === type)?.branchId;
  }

  public static getAllProfileTypes(): ProfileType[] {
    return Object.values(ProfileType);
  }
}
