import {BehaviorSubject} from 'rxjs';

export type SetStateFn<T> = (prev: T) => T;
export type SetStateArgs<T> = T | SetStateFn<T>;

export class StateSubject<T> extends BehaviorSubject<T> {
  private state: T;

  constructor(initialState: T) {
    super(initialState);
    this.state = initialState;
  }

  getValue(): T {
    if (this.hasError) {
      throw this.thrownError;
    } else if (this.closed) {
      throw this.thrownError;
    } else {
      return this.state;
    }
  }

  hasValue(): boolean {
    return !!this.state;
  }

  next(value: T): void {
    super.next((this.state = value));
  }

  setState(arg: SetStateArgs<T>): void {
    if (typeof arg === 'function') {
      super.next((this.state = (arg as SetStateFn<T>)(this.value)));
    } else {
      super.next((this.state = arg));
    }
  }
}
