import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {SidenavItem} from './sidenav-item';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  private sidenavItemsSubject = new BehaviorSubject<SidenavItem[]>([]);
  public sidenavItems$: Observable<SidenavItem[]> = this.sidenavItemsSubject.asObservable();

  public opened: boolean = false;

  get sidenavItems(): SidenavItem[] {
    return this.sidenavItemsSubject.value;
  }

  set sidenavItems(sidenavItems: SidenavItem[]) {
    this.sidenavItemsSubject.next(sidenavItems);
  }

  public toggle(): void {
    this.opened = !this.opened;
  }
}
