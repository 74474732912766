<div *appAuthorize="['participant']">
  <div *ngIf="isLoading" class="loading-shade">
    <mat-spinner></mat-spinner>
  </div>
  <ng-template #noResults>
    <div *ngIf="!isLoading">
      No school staff or chaplains have been configured for your departments.
    </div>
  </ng-template>
  <h1 class="m-3 text-center">Reach Out for Assistance</h1>
  <div *ngIf="(caseResponse | async) as caseResponse; else noResults" class="container">
    <form (submit)="onSubmit()"
          [formGroup]="form">
      <div *ngIf="caseResponse?.counselors?.length > 0; else noResults">
        <div class="px-3 py-2">
          <p>
            Your privacy is important to us, so we will never share any of your personal screening information unless you choose to. We have vetted and confidential resources assigned to your locations that are here to help you if you need it.
            <br />
            <br />
            To reach out for assistance, please select one or more available {{getBranchText()}} resources, the screening(s) you would like to share, and a comment explaining why you are reaching out. The chosen screening(s) will ONLY be shared with the {{getBranchText()}} resource(s) that you selected.
          </p>
        </div>
        <div class="row px-3 py-1">
          <div class="col-md">
            <mat-form-field class="w-100">
              <mat-label>Select School Staff or Chaplains</mat-label>
              <mat-select #counselorSelect formControlName="counselors" multiple required>
                <mat-select-trigger>
                  {{ getCounselorNames() }}
                </mat-select-trigger>
                <mat-optgroup *ngFor="let group of caseResponse.counselors" [label]="group.departmentName">
                  <mat-option (click)="onCounselorSelect(option.counselorName, option.organizationId)"
                              *ngFor="let option of group.counselors"
                              [value]="option">
                    {{ option.counselorName }}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row px-3 py-1">
          <div class="col-md">
            <mat-form-field class="w-100">
              <mat-label>Select Screenings</mat-label>
              <mat-select (selectionChange)="onAssessmentSelectionChange()" formControlName="assessments" multiple
                          required>
                <mat-option *ngFor="let option of  caseResponse.assessmentResults"
                            [disabled]="isDisabled(option.id)"
                            [value]="option">
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row px-3 py-1">
          <div class="col-md">
            <mat-form-field class="w-100">
              <mat-label>Comments</mat-label>
              <textarea [rows]="3" formControlName="comments" matInput placeholder="comments"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="assessmentsResultCases?.length > 0" class="row px-3 py-1">
          <div class="col-md">
          <strong>The following screenings will be shared:</strong>
          <li *ngFor="let assessmentResult of assessmentsResultCases">
            {{assessmentResult.label}}
          </li>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-around" mat-dialog-actions>
          <div *ngIf="caseResponse?.counselors?.length > 0" class="col-md-6">
            <button
              [disabled]="form.invalid"
              class="w-100"
              color="primary"
              mat-stroked-button>
              <mat-icon class="mr-2">send</mat-icon>
              Send
            </button>
          </div>
          <div class="col">
          <button
            (click)="onCancel()"
            class="w-100"
                  color="warn"
                  mat-stroked-button
                  type="button">
            <mat-icon class="mr-2">cancel</mat-icon>
            {{ cancelText }}
          </button>
          </div>
        </div>
    </form>
  </div>
</div>
