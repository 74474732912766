<div class="d-flex flex-row align-items-center quick-search-wrapper">
  <button *ngIf="!expand" id="search-student-icon" mat-icon-button matTooltip="Search for a Student" (click)="expand = true">
    <mat-icon color="primary">search</mat-icon>
  </button>
  <div *ngIf="expand">
      <mat-form-field appearance="outline">
        <input type="text"
               id="student-search-input-box"
               class="searchInput"
               label="Search Student"
               placeholder="Student Name or ID"
               matInput
               [formControl]="myControl"
               [matAutocomplete]="auto">
      </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="goToDetails($event.option.value)" [displayWith]="getOptionName">
      <mat-option *ngFor="let option of (filteredOptions$ | async)" [value]="option">
        {{ option.user?.firstName + ' ' + option.user?.lastName + ': ' + option.user?.dateOfBirth }}
      </mat-option>
      <mat-option class="route tour-counselor-dashboard-student-search-dropdown-advanced" routerLink="schools/participant-advanced-search">Advanced Search <mat-icon>search</mat-icon></mat-option>
    </mat-autocomplete>
  </div>
  <div *ngIf="expand">
    <button id="close-search" mat-icon-button matTooltip="Close Search" (click)="expand = false">
      <mat-icon color="primary">close</mat-icon>
    </button>
  </div>
</div>
